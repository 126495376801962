import "@babel/polyfill";
import $ from 'jquery';

import loader from './__modules/loader';
import main from './__modules/main';
import gallery from './__modules/gallery';

document.addEventListener('DOMContentLoaded', () => {
	
	if($(".main").length) {
		main();
	}

	if($(".gallery").length) {
		gallery();
	}
	
	$(".menu-overlay li").each(function(index, submenu) {
		if($(submenu).children('ul').length) {
			$(submenu).addClass("submenu");
		}
	});

	$(".menu-overlay a").on("click", function(e) {
		if($(this).closest('li').has('> ul').length) {
			e.preventDefault();

			let _parent = $(this).parent();
			
			if($(".menu-open").length) {
				if(_parent.hasClass("menu-open")) {
					_parent.toggleClass("menu-open").find("> ul").slideToggle();
				} else {
					if(_parent.siblings().hasClass("menu-open")) {
						let siblingItem = _parent.siblings(".menu-open");

						_parent.siblings(".menu-open").toggleClass("menu-open").find("> ul").slideToggle(400, function() {
							siblingItem.find("li.menu-open").remove
							_parent.toggleClass("menu-open").find("> ul").slideToggle();
						});
					} else {
						_parent.toggleClass("menu-open").find("> ul").slideToggle();
					}
				}
			} else {
				_parent.toggleClass("menu-open").find("> ul").slideToggle();
			}
		}
	});

	$(".menu-overlay-2 a").on("click", function(e) {

		if($(this).closest('li').has('> ul').length) {
			e.preventDefault();

			if($(".menu-open").length) {
				var _this = $(this);

				if($(this).parents("ul.menu-open").length) {
					// alert("1");
					$(this).closest('li').find('> ul').toggleClass('menu-open').slideToggle();
				} else {
					$("ul.menu-open").slideToggle(400, function() {
						$("ul.menu-open").toggleClass("menu-open");
						_this.closest('li').find('> ul').toggleClass('menu-open').slideToggle();
					});
				}
			} else {
				$(this).closest('li').find('> ul').toggleClass('menu-open').slideToggle();
			}
		}
	});

});

window.addEventListener('load', () => {
});

window.addEventListener('resize', () => {
});

window.addEventListener('scroll', () => {

	if($(window).scrollTop() > 0) {
		$(".header").addClass("header-scrolled");
	} else {
		$(".header").removeClass("header-scrolled");
	}

});