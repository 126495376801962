import $ from 'jquery';
import Swiper, { Navigation, Autoplay, EffectFade } from 'swiper/bundle';

const main = () => {
    const mainSlider = new Swiper('.main .swiper', {
        modules: [Navigation, Autoplay, EffectFade],
        pagination: false,
        scrollbar: false,
        direction: 'horizontal',
        loop: true,
        speed: 800,
        autoplay: {
            delay: 10000
        },
        navigation: {
            nextEl: '.main-arrow--right',
            prevEl: '.main-arrow--left',
        },
    });
};

export default main;