import $ from 'jquery';

var Flickity = require('flickity');

const gallery = () => {
    var flkty = new Flickity( '.main-carousel', {
        cellAlign: 'center',
        contain: true,
        wrapAround: true,
        //fullscreen: true,
        pageDots: false,
        imagesLoaded: true,
        arrowShape: "M468.267 42.667l121.472 120.661-263.509 263.509h697.771v170.325h-697.771l263.509 263.509-121.472 120.661-468.267-469.333z"
    });
};

export default gallery;